html {
    scroll-behavior: smooth;
}
img {
	border-radius: 4%;
  }

body {
  background-color: #f44336;
  		background-image: linear-gradient(white, #e64a19, red, white, blue);
		  padding: 10px;
  margin: 0;
}

h1, h2, p, ul, li {
	 color: #ffffff;
  font-family: verdana;
  font-size: 1,2em;
}
div.header li {
	background-color: #000;
  display: inline;
  padding: 10px;
  list-style-type: none;
  margin: 0;
  border-radius: 25px;
}
div.header li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}


.content h2 {
  padding: 20px;
  margin: px;
}
.content li {
  margin-bottom: 10px;
}

.article3 {
	color: #red;
	padding-left: 15%;
    margin-left: 15px;
	padding-top: 15px;
    margin-top: 15px;
	padding-right: 15%;
    margin-right: 15px;
	padding-bottom: 15px;
    margin-bottom: 15px;
	border-radius: 25px;
	-webkit-box-shadow: 0 12px 5px rgba(0, 0, 0, .3);   
	-moz-box-shadow: 0 12px 5px rgba(0, 0, 0, .3);
    box-shadow: 0 12px 5px rgba(0, 0, 0, .3);
	border-bottom: 8px solid;
	border-bottom-color: red;
		background-color: #eee;
		background-image: -moz-linear-gradient(#fff,#eee);
		background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#eee));    
		background-image: -webkit-linear-gradient(#fff, #eee);
		background-image: -o-linear-gradient(#fff, #eee);
		background-image: -ms-linear-gradient(#fff, #eee);
		background-image: linear-gradient(white, #e64a19);
}


 section {
	/* background-color: #fbc02d; */
	background-color: red;
    color: white;
	font-family: verdana;
    font-size: 1em;
	border-radius: 25px;
	padding-bottom: 0px;
	padding-top: 1px;
	padding-left: 10px;
	
 }
h1 {
	color: #fff;
}

.uitleg1{
	position: relative;
	left: -10px;
	top: -540px;
	z-index: -0;
	width: 50%;
	color: black;

}

.uitleg2{
	position: relative;
	left: 100px;
	top: -495px;
	z-index: -0;
	width: 50%;
	color: black;

}

.uitleg3{
	position: relative;
	left: 100px;
	top: -390px;
	z-index: -0;
	width: 50%;
	color: black;
}

.uitleg4{
	position: relative;
	left: 100px;
	top: -290px;
	z-index: -0;
	width: 50%;
	color: black;
}

.constructie{
color:#e64a19;
font-size: 1.7em;
font-family: Verdana;
}
	




